import React from "react";
import { IAccountNavigationProps } from "./types";

import cls from "./account.module.scss"

const AccountNavigationMenu: React.FC<IAccountNavigationProps> = ({ links, username }) => {
    return (
        <div className={cls["account-layout"]}>
            <div className={cls["account-nav"]}>
                <ul className={`${cls.card} ${cls["account-links-body"]}`}>
                    {username ? <li className={cls["account-nav-username"]}>
                        <span>{username}</span>
                    </li> : null}
                    {links}
                </ul>
            </div>
        </div>
    )
}

export default AccountNavigationMenu;