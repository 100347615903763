import cls from "./customHeader.module.scss"
import React, {FC} from "react";
import {ISecondHeader} from "../MainLayout";
import CustomDepartments from "./CustomDepartments";
import CustomSearch from "./CustomSearch";
import CustomIndicatorPanel from "./CustomIndicators/CustomIndicatorPanel";
import CustomIndicatorCart from "./CustomIndicators/CustomIndicatorCart";
import {useSelector} from "react-redux";
import {IState} from "../../../../types/state";

const SecondSection: FC<ISecondHeader> = (
    {
        categories,
        isTwoKings,
        dbName,
        selectedRate,
        selectedLocale,
        translationsData,
        backOrderValue,
        isSocialLinksActive,
        allowCheckoutValue,
        isMobile,
    }
) => {
    const openSearch = useSelector((state: IState) => state.mobileMenu.searchOpen)

    const classSearchOpen = {
        true: `${cls["mobile-header__search--open"]} ${cls["mobile-header__search"]}`,
        false: cls["mobile-header__search"]
    }

    return <div className={cls.second_section}>
        <div className={`${cls["nav-panel"]}`}>
            <div className={`${cls["nav-panel__container"]} container`}>
                <div className={`${cls["nav-panel__row"]}`}>
                    <div className={cls["nav-panel__departments"]}>
                        <CustomDepartments categories={categories} isTwoKings={isTwoKings} dbName={dbName}/>
                    </div>
                    <div className={`${cls["site-header__search"]} ${classSearchOpen[`${openSearch}`]}`}>
                        <CustomSearch
                            translationsData={translationsData}
                            selectedLocale={selectedLocale}
                            dbName={dbName}
                            selectedRate={selectedRate}
                            backOrderValue={backOrderValue}
                        />
                    </div>
                    <div className={`${cls["nav-panel__indicators"]}`}>
                        <CustomIndicatorPanel
                            isSocialLinksActive={isSocialLinksActive}
                            translationsData={translationsData}
                            selectedLocale={selectedLocale}
                            selectedRate={selectedRate}
                            dbName={dbName}
                            isMobile={isMobile}
                        />
                        <div className={`${cls["nav-panel__item_row"]}`}>
                            <CustomIndicatorCart
                                allowCheckoutValue={allowCheckoutValue}
                                translationsData={translationsData}
                                dbName={dbName}
                                selectedRate={selectedRate}
                                locale={selectedLocale}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default SecondSection;