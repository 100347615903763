import {cartAddItem, getUpOrCrossProd} from "../../../store/cart";
import {Dispatch} from "react";
import {IProduct} from "../../../types/homePageTypes";
import {ICurrencies} from "../../ForCWV/Wrappers/MainLayout";
import {setCrossValid, setPopupName, setTempData} from "../../../store/general";

export const addToCartEventPixel = (product: IProduct, quantity: number, selectedRate: ICurrencies) => {
    if (window.fbq) {
        window.fbq('track', 'AddToCart', {
            product_name: product.flats[0].name,
            product_sku: product.sku,
            product_id: product.flats[0].id,
            product_type: product.type,
            product_price: product.flats[0].price,
            product_quantity: quantity,
            currency: selectedRate.code
        });
    }
};

type attrAddCartFuncType = {
    openUpCrossProd: () => Promise<boolean>,
    dispatch: Dispatch<any>,
    data: {
        product: IProduct,
        quantity: number,
        cartToken: { cartToken: string },
        customer: { token: string },
        selectedLocale: string,
        selectedCurrCode: string,
        optionsObject: null,
        addToast: (error?: boolean) => void,
        links?: number[],
        bundle_options?: Record<string, Array<number>> | null,
        bundle_option_qty?: Record<string, number> | null
    },
    routerPush: (url: string) => Promise<boolean>,
    isProductInStock: boolean;
}

type attrUpCrossOpenType = {
    dispatch: Dispatch<any>;
    productId: number;
    selectedLocale: string;
    selectedRate: ICurrencies;
    product: IProduct
}

export const handleAddCard = async (
    {
        openUpCrossProd,
        dispatch,
        data: {
            product,
            quantity,
            cartToken,
            customer,
            selectedLocale,
            selectedCurrCode,
            optionsObject,
            addToast,
            links = [],
            bundle_options,
            bundle_option_qty
        },
        routerPush,
        isProductInStock,
    }: attrAddCartFuncType
) => {
    const {
        flats: [{
            has_up_sell,
            has_cross_sell,
            url_key
        }]
    } = product
    const addItemCallback = () => cartAddItem(
        product,
        quantity,
        cartToken,
        customer as { token: string },
        selectedLocale,
        selectedCurrCode,
        optionsObject,
        addToast,
        links,
        bundle_options,
        bundle_option_qty
    )
    const anywayFunc = () => {
        addToCartEventPixel(product, quantity, { code: selectedCurrCode } as ICurrencies);
        dispatch(addItemCallback());
    }
    if (isProductInStock) {
        if (has_up_sell) {
            if (!(await openUpCrossProd())) {
                dispatch(addItemCallback())
            } else {
                await openUpCrossProd()
            }
        } else {
            if (has_cross_sell) await openUpCrossProd()
            anywayFunc()
        }
    } else await routerPush(`/${url_key}`)
}

export const openUpCrossProd = async (
    {
        dispatch,
        productId,
        selectedLocale,
        selectedRate,
        product
    }: attrUpCrossOpenType
) => {
    const {
        flats: [{
            has_up_sell,
            has_cross_sell,
        }]
    } = product
    const callBackForUpOrCross = (type: 'up-sell' | 'cross-sell') => {
        return getUpOrCrossProd(
            productId,
            type,
            selectedLocale,
            selectedRate
        )
    }
    if (parseFloat(has_up_sell)) {
        const [upResponse = []] =
            await Promise.all(
                [dispatch(callBackForUpOrCross("up-sell"))]
            ) as unknown as [IProduct[]] || [[]]
        if (upResponse.length > 0) {
            dispatch(setPopupName("upsell"));
            dispatch(setTempData([product]))
            return true
        }
        return false
    }
    if (parseFloat(has_cross_sell)) {
        const [crossResponse = []] =
            await Promise.all(
                [dispatch(callBackForUpOrCross("cross-sell"))]
            ) as unknown as [IProduct[]] || [[]]
        if (crossResponse.length > 0) {
            dispatch(setCrossValid(true));
            dispatch(setPopupName("crossSell"));
            dispatch(setTempData([product]))
            return true
        }
        return false
    }
    return true
}
