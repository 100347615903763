import React, {FC, MouseEventHandler} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Link from 'next/link'
import {Cart16Svg, CheckToastSvg, FailSvg} from '../../../../../svg'
import {IState} from "../../../../../types/state";
import {domainUrl} from '../../../../../helper'
import cls from "./customSearch.module.scss"
import Image from "next/image";
import {IProduct, TranslationsType} from "../../../../../types/homePageTypes";
import {useRouter} from "next/router";
import {ICurrencies} from "../../MainLayout";
import {useToast} from "../../../../../atoms/Toast/useToast";
import {handleAddCard, openUpCrossProd} from "../../../../shared/ProductCard/helpers";

interface IProps {
    products: Array<IProduct>;
    selectedRate: ICurrencies;
    dbName: string;
    translationsData: TranslationsType;
    backOrderValue: string;
    selectedLocale: string
}

const CustomSuggestions: FC<IProps> = (
    {
        products,
        selectedRate,
        dbName,
        translationsData,
        backOrderValue,
        selectedLocale
    }
) => {
    const dispatch = useDispatch();
    const {push: routerPush} = useRouter();
    const toast = useToast();
    const cartToken = useSelector((state: IState) => state.cartToken);
    const customer = useSelector((state: IState) => state.customer)
    const addToast = (error?: boolean, isProductInStock?: boolean) => {
        if (error) {
            toast.open(
                <span className="d-flex chek-fms">
                    <FailSvg/>
                    {translationsData.ProductNotAvailable}
                </span>
            )
        } else {
            if (isProductInStock) {
                toast.open(
                    <span className="d-flex chek-fms">
                        <CheckToastSvg/>
                            {translationsData.ToastAddToCart}
                   </span>
                )
            }
        }
    }
    const handleCardClick: (product: IProduct) => Promise<void> = async (product) => {
        const {flats: [{url_key}]} = product
        const isProductInStock = product.qty[0] > 0 || !!parseFloat(backOrderValue)
        if (
            product.type === "downloadable"
            || product?.type === "configurable"
            || product?.type === "bundle"
        ) await routerPush(`/${url_key}`);
        const addToastSend = (error?: boolean) => addToast(error, isProductInStock)
        await handleAddCard({
            openUpCrossProd: () => {
                return openUpCrossProd(
                    {
                        dispatch,
                        productId: product.id,
                        selectedLocale,
                        selectedRate,
                        product
                    }
                )
            },
            dispatch,
            data: {
                product,
                quantity: 1,
                cartToken,
                customer: customer as { token: string },
                selectedLocale,
                selectedCurrCode: selectedRate.code,
                optionsObject: null,
                addToast: addToastSend
            },
            routerPush,
            isProductInStock,
        })
    }
    const ProductsExist = ({ product }: { product: IProduct }) => {
        if (!products.length) {
            return (
                <div className={cls.search__fm_dr_Message}>
                    {translationsData?.NoMatchingItems}
                </div>
            )
        }
        const productTypesWithQty = ["simple", "virtual"];
        const SuggestionsActionButton = ({ handleClick }: { handleClick?: MouseEventHandler<HTMLButtonElement> }) => (
            <div className={`${cls["suggestions__item-actions"]}`}>
                <button
                    type="button"
                    className={`btn btn-primary btn-sm btn-svg-icon ${cls["suggestion-btn"]}`}
                    onClick={handleClick}
                >
                    <Cart16Svg/>
                </button>
            </div>
        )
        const cardBasketRedirect = (
            <Link href={`/${product.flats[0].url_key}`}>
                <a><SuggestionsActionButton /></a>
            </Link>
        )
        const ListJsx = ({ basketJsx = cardBasketRedirect }) => {
            return (
                <Link
                    className={cls["suggestions__item-name"]}
                    href={`/${product.flats[0].url_key}`}
                    key={product.id}
                >
                    <li className={cls.suggestions__item}>
                        <div className={`${cls["suggestions__item-image"]} ${cls["product-image"]}`}>
                            <div className={`${cls["search-product-image__body-fms"]}`}>
                                <Link href={`/${product.flats[0].url_key}`}>
                                    <a>
                                        <Image
                                            src={domainUrl(`${dbName}${product?.images[0]}`)}
                                            alt={product?.flats[0]?.name}
                                            width={50}
                                            height={50}
                                            priority
                                        />
                                    </a>
                                </Link>
                            </div>
                        </div>
                        <div className={cls["suggestions__item-info"]}>
                            <a> {product?.flats[0]?.name}</a>
                            <div className={cls["suggestions__item-meta"]}>
                                {`SKU: ${product?.sku}`}
                            </div>
                        </div>
                        <div className={cls["suggestions__item-price"]}>
                            {selectedRate?.symbol}
                            {" "}
                            {product.flats[0]?.special_price ?? product.flats[0]?.price}
                            {' '}
                        </div>
                        {basketJsx}
                    </li>
                </Link>
            )
        }
        const isProductInStock = (product.qty[0] > 0 || parseFloat(backOrderValue))
            && (!product.flats[0].min_qty || product.flats[0].min_qty <= 1)
        if (productTypesWithQty.some(type => product.type === type) && isProductInStock) {
            return <ListJsx basketJsx={
                <SuggestionsActionButton handleClick={async (e) => {
                    e.preventDefault();
                    await handleCardClick(product);
                }}/>
            }/>;
        }
        return <ListJsx />
    }
    return (
        <div className={`${cls.suggestions} ${cls[`suggestions--location--header`]} ${cls.search__suggestions}`}>
            <ul className={cls["suggestions__list"]}>
                {products.map((product: IProduct) => <ProductsExist product={product} key={product.id} />)}
            </ul>
        </div>
    )
};

export default CustomSuggestions