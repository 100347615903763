import React, {FC, JSX, useEffect, useState} from "react";
import cls from "../customHeader.module.scss"
import {useSelector} from "react-redux";
import {IState} from "../../../../../types/state";
import {TIcon} from "./types";
import CustomIndicator from "./CustomIndicator";
import CustomIndicatorDropDownBody from "./CustomIndicatorDropDownBody";
import {TranslationsType} from "../../../../../types/homePageTypes";
import {ICurrencies} from "../../MainLayout";

interface IIndicatorPanel {
    isSocialLinksActive: Array<Record<string, string>>;
    translationsData: TranslationsType;
    selectedLocale: string;
    selectedRate: ICurrencies;
    dbName: string
}

const CustomIndicatorAccount: FC<IIndicatorPanel> = ({
                                                         translationsData,
                                                         selectedLocale,
                                                         selectedRate,
                                                         isSocialLinksActive,
                                                         dbName
                                                     }) => {
    const authenticated = useSelector((state: IState) => state.customer.authenticated);
    const [open, setOpen] = useState<boolean>(false);
    const [iconWrapperClassName, setIconWrapperClassName] = useState<string>("user_stroke")
    const [icon, setIcon] = useState<TIcon>("userIcon")
    const [title, setTitle] = useState<string>(translationsData?.SignIn)

    useEffect(() => {
        const settersToggle: (arrElem: Array<string>) => void = ([first, second, third]) => {
            setIconWrapperClassName(first)
            setIcon(second as TIcon)
            setTitle(translationsData?.[third])
        }
        const isAuthenticated = {
            "true": () => settersToggle(["desktop_user_active__wrapper", "userIcon_active", "MyAccount"]),
            "false": () => settersToggle(["user_stroke", "userIcon", "SignIn"])
        }
        isAuthenticated[`${authenticated}`]()
    }, [authenticated, selectedLocale])


    const dropdown: Record<string, JSX.Element | string> = {
        "false": (
            <CustomIndicatorDropDownBody
                isSocialLinksActive={isSocialLinksActive}
                setOpen={setOpen}
                translationsData={translationsData}
                selectedLocale={selectedLocale}
                selectedRate={selectedRate}
                dbName={dbName}
            />
        ),
        "true": ""
    }

    return (
        <CustomIndicator
            iconWrapperClassName={iconWrapperClassName}
            url={authenticated ? "/account/profile" : ""}
            className={`${cls["account-logo-svg"]}`}
            dropdown={dropdown[`${authenticated}`]}
            icon={icon}
            title={title}
            value=""
        />
    );
}

export default CustomIndicatorAccount;